import { createContext, useContext } from 'react';

// Allow list for public environment variables
// Edit this array to expose other runtime environment variables to the browser.
// Do not expose secrets here
export const publicEnvKeys = [
  'STRIPE_PUBLISHABLE_KEY',
  'GOOGLE_ANALYTICS_ID',
] as const;

export type PublicEnvKey = (typeof publicEnvKeys)[number];

export type PublicEnv = Record<PublicEnvKey, string>;

export function buildPublicEnv(): PublicEnv {
  return publicEnvKeys.reduce((env, key) => {
    if (process.env[key] === undefined) {
      throw new Error(`Missing environment variable: ${key}`);
    }
    env[key] = process.env[key]!;
    return env;
  }, {} as PublicEnv);
}

export const EnvContext = createContext<PublicEnv | null>(null);

export function useEnv(key: keyof PublicEnv): string | null {
  const env = useContext(EnvContext);
  if (env === null) return null;
  return env[key];
}
